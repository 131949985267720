

































































































































































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { postShareStore } from '@/stores/post-share'

declare var Kakao: any

@Observer
@Component({
  components: {},
})
export default class ShareDialog extends Vue {
  postShare = postShareStore
  copyTooltip = false
  tooltipTimeout: any = null

  shareKakaoTalk() {
    Kakao.Share.sendDefault({
      objectType: 'text',
      text: this.postShare.postLink,
      link: {
        mobileWebUrl: 'https://developers.kakao.com',
        webUrl: 'https://developers.kakao.com',
      },
    })
  }

  copyLink() {
    const link = this.postShare.postLink
    navigator.clipboard.writeText(link)
    this.copyTooltip = true
    if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout)
    this.tooltipTimeout = setTimeout(() => {
      this.copyTooltip = false
      this.tooltipTimeout = null
    }, 5000)
  }

  facebookShare() {
    const fbButton = document.getElementById('fb-share') as any
    if (fbButton) (fbButton as any).click()
  }

  twitterShare() {
    const twitterShareBtn = document.getElementById('tw-share')
    if (twitterShareBtn) twitterShareBtn.click()
  }
}
